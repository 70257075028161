import './App.css';
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import AppLayout from '@cloudscape-design/components/app-layout';

import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Flashbar from "@cloudscape-design/components/flashbar"
import Link from "@cloudscape-design/components/link"

import { useState, useEffect} from 'react';

import PlayerRegistration from "./components/PlayerRegistration";
import GameView from './pages/GameView';
import ClientView from './pages/ClientView';

import LandingPage from './pages/LandingPage'
import PageHeader from './components/PageHeader';

import { getActiveGame } from "./utils/API";
import awsExports from './aws-exports';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { subscribe } from './graphql/subscriptions';
import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';

function App() {

  
  const [ clientId, setClientId ] = useState("")
  const [ isGameInProgress, setIsGameInProgress ] = useState(false)
  const [ gameId, setGameId ] = useState(0)
  const [ items, setItems] = useState([]);

  let addFlashbarItem = (newItem) => {
    newItem['onDismiss'] = () => setItems(items => {
      items.filter(item => item.id !== newItem['id'])
    })
    //console.log(newItem)
    //let newflashbarItems = flashbarItems
    //newflashbarItems
    setItems(items => [...items, newItem])
    console.log(items)
  }
  
  applyMode(Mode.Dark);


  useEffect(() =>{
    //console.log(sessionStorage.getItem('clientId'))
    setClientId(sessionStorage.getItem('clientId'))
    checkGameStatus(sessionStorage.getItem('clientId'))


  }, [])


  let checkGameStatus = (clientId) => {

    getActiveGame(clientId).then(
      (result) => {
        //console.log(result)
        if(result !== "No Active Games"){
          setIsGameInProgress(true)
          setGameId(result?.['id'])
        } else {
          setIsGameInProgress(false)
          setGameId(0)
        }
      },
      (error) => {

      }
    )

  }

  return (
    <div className="App" >
            <div style={{backgroundColor: "#0E1b2A", margin: "30px"}}>
              

                <div className="app-content">
                  <PageHeader setClientId={setClientId} clientId={clientId}/>
                  <Router>
                    <Routes>
                      <Route path="/" element={<LandingPage clientId={clientId} addFlashbarItem={setItems}/>} />
                      <Route path="/register" element={<PlayerRegistration clientId={clientId} setIsGameInProgress={setIsGameInProgress} />} />
                      <Route path="/client-view" element={<ClientView gameId={gameId}/>} />
                      <Route path="/game-view" element={<GameView gameId={gameId}/>} />
                    </Routes>
                  </Router>
                </div>

            </div>
         
    </div>
  );
}

export default App;

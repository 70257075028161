import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import { useState, useEffect} from 'react';

import Modal from "@cloudscape-design/components/modal"
import Form from "@cloudscape-design/components/form"
import Container from "@cloudscape-design/components/container"
import FormField from "@cloudscape-design/components/form-field"
import Input from "@cloudscape-design/components/input"
import Spinner from "@cloudscape-design/components/spinner";

import { clientLogin } from '../utils/API';

let PageHeader = (props) => {
    let clientId = sessionStorage.getItem('clientId');

    const [ loading, setLoading ] = useState(false)
    const [ loginButtonText, setLoginButtonText ] = useState("Login");
    const [ loginModalVisible, setLoginModalVisible ] = useState(false);
    const [ errorText, setErrorText ] = useState("");
    const [ loginDetails, setLoginDetails ] = useState({
        "clientId": "",
        "password": ""
    })

    useEffect(() =>{
        //console.log(clientId)
        if(clientId){
            setLoginButtonText("Logout")
        }
    
    
      }, [])

    return (
        <div>
            <Header
                variant="h1"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button
                    onClick={(event) => {
                        if(loginButtonText === "Login"){
                            setLoginModalVisible(true);
                        }
                        else if(loginButtonText === "Logout"){
                            sessionStorage.setItem('clientId', "")
                            sessionStorage.setItem('displayName', "")
                            setLoginButtonText("Login")
                            window.location.reload(false);
                        }
                    }}
                    
                    >{loginButtonText}</Button>
                    </SpaceBetween>
                }
            >
              AWS Smart Foosball - {clientId}
            </Header>
            < br />

            <Modal
                onDismiss={() => setLoginModalVisible(false)}
                visible={loginModalVisible}
                header="Login"
            >
                { loading ? <Spinner /> :
                <form onSubmit={e => e.preventDefault()}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal"  size="xs">
                                <Button formAction='none' variant='link'
                                    onClick={() => setLoginModalVisible(false)}
                                >
                                    Cancel
                                </Button>
                                <Button variant="primary"
                                    onClick={(event) => {
                                        setLoading(true)
                                        clientLogin(loginDetails).then(
                                            (result) => {
                                                //console.log('result ' + result)
                                                //console.log(result)
                                                if(result['statusCode'] == 401){
                                                    setErrorText(result['body'])
                                                    setLoading(false)
                                                } else if (result['statusCode'] == 200){
                                                    sessionStorage.setItem('clientId', result['body']['clientId'])
                                                    sessionStorage.setItem('displayName', result['body']['displayName'])
                                                    props.setClientId(result['body']['clientId'])
                                                    //console.log(sessionStorage.getItem('clientId'))
                                                    setErrorText('')
                                                    setLoginDetails({
                                                            "clientId": "",
                                                            "password": ""
                                                    })
                                                    setLoading(false)
                                                    setLoginButtonText("Logout")
                                                    setLoginModalVisible(false)
                                                }
                                            },
                                            (error) => {
                                                //console.log('error ' + error)
                                            }
                                        )
                                    }}
                                >
                                    Login
                                </Button>
                            </SpaceBetween>
                        }
                        errorText={errorText}
                    >
                        <Container
                            header={
                                <Header variant="h2">
                                    
                                </Header>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <FormField label="Client Id">
                                    <Input 
                                        onChange={({ detail }) => setLoginDetails({...loginDetails, "clientId": detail.value})}
                                        type="text"
                                        value={loginDetails['clientId']}
                                    />
                                </FormField>
                                <FormField label="Password">
                                    <Input
                                        onChange={({ detail }) => setLoginDetails({...loginDetails, "password": detail.value})}
                                        type="password"
                                        value={loginDetails['password']}
                                    />
                                </FormField>
                            </SpaceBetween>

                        </Container>

                    </Form>
                </form>
                }
            </Modal>
        </div>
    )
}

export default PageHeader;
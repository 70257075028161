
import { useState, useEffect, useRef } from "react";
import ReactHlsPlayer from 'react-hls-player';

let Video = (props) => {
    const player = useRef("HTMLVmPlayerElement");
    const [url, setUrl] = useState(props.videoUrl)
    const [type, setType ] = useState(props.setType)

    useEffect(() => {
        //console.log("now playing")
        //console.log(props.videoUrl)
        setUrl(props.videoUrl)
        setType(props.type)
        //console.log(player.current.currentSrc)
    }, [props.videoUrl, props.type])
 
        return(
                <div>
                    <ReactHlsPlayer
                        src={url}
                        autoPlay
                        width="100%"
                        controls={false}
                        muted
                        playsInline
                    />           
                </div>
        )
}


export default Video;

import './scoreboard.css';
import React, { useState, useEffect } from 'react';

const gameClipUrl = 'https://dzpyvkbdj48lt.cloudfront.net';





let Scoreboard = (props) => {
    let team2GameClipUrls = props.team2GameClipUrls;
    let team1GameClipUrls = props.team1GameClipUrls;
    let gameStatus = props.gameStatus;

     let team1ScoreColor = 'red'

    let team2ScoreColor = 'blue'

    let returnScoreboard = (status) =>{
        if(gameStatus){
            return(
                <div className="scoreboard-container">
                <div id="scoreboard-item">
                    <div id="score-name">
                        {props.team1Players[0]}
                    </div>
                    <div id="score-name">
                        {(props.team1Players.length > 1) ? "&" : ""}
                    </div>
                    <div id="score-name-bottom" style={{borderColor: team1ScoreColor}}>
                    {props.team1Players[1]}
                    </div>
                    <div id="score-goal-replays">
                        {   
                             Object.keys(props.team1GameClipUrls).map((item, index) =>{
                                    return(
                                    <div 
                                        className="goal-replay-button"
                                        style={{borderColor: team1ScoreColor}} 
                                        id={index} key={"T1S" + index} 
                                        onClick={(e) => {
                                            props.onGoalReplayClick(
                                                team1GameClipUrls[e.target.id]
                                                    .replace('s3://sfb-prod1-games', gameClipUrl)
                                            )}
                                        }
                                    >
                                        {index+1}
                                    </div>
                                    )
                                })
                        }

                    </div>
                </div>
                <div id="score">
                    <div className="score-number-main" style={{color: team1ScoreColor}}>
                        {props.team1Score}
                    </div>
                    <div className="score-number-main">
                    -
                    </div>
                    <div className="score-number-main" style={{color: team2ScoreColor}}>
                    {props.team2Score}
                    </div>
                </div>
                <div id="scoreboard-item">
                    <div id="score-name">
                        {props.team2Players[0]}
                    </div>
                    <div id="score-name">
                        {(props.team2Players.length > 1) ? "&" : ""}
                    </div>
                    <div id="score-name-bottom" style={{borderColor: team2ScoreColor}}>
                        {props.team2Players[1]}
                    </div>
                    <div id="score-goal-replays">
                        {   
                            props.team2GameClipUrls.map((item, index) =>{
                                return(
                                <div 
                                    className="goal-replay-button"
                                    style={{borderColor: team2ScoreColor}}  
                                    id={index} key={"T2S" + index} 
                                    onClick={(e) => {
                                        props.onGoalReplayClick(
                                            team2GameClipUrls[e.target.id]
                                                .replace('s3://sfb-prod1-games', gameClipUrl)
                                        )}
                                    }
                                >
                                    {index+1}
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            )
        }
        else {
            return(
                <div > No Game in Progress</div>
            )
        }
    }

    return (
        <div id='score-background'>
            {
                returnScoreboard(gameStatus)
            }
            
        </div>
    )

}


export default Scoreboard;

import "./gamecard.css"

let GameCard = (props) => {

    const gameClipUrl = 'https://dzpyvkbdj48lt.cloudfront.net';
    const gameMontageUrl = 'https://dzpyvkbdj48lt.cloudfront.net';

    let team1Winner = false;

    let team2Winner = false;

    if(props.team2Score > props.team1Score){
        team2Winner = true
    } else if(props.team2Score < props.team1Score) {
        team1Winner = true
    }


    let team1ScoreColor = 'red'

    let team2ScoreColor = 'blue'

    //console.log('montageurl')
    //console.log(props.montageUrl)

    let montage_url = props.montageUrl.replace('s3://sfb-montage-output', gameMontageUrl)

    let team1GoalClipUrls = props.team1GoalClipUrls
    let team2GoalClipUrls = props.team2GoalClipUrls

    let team1ScoreStyle = {"color": team1ScoreColor, "fontSize": "3.5em", };
    let team2ScoreStyle = {"color": team2ScoreColor, "fontSize": "3.5em", };

    if(team1Winner){
        team1ScoreStyle = {"color": team1ScoreColor, "fontSize": "4.5em", }
    } else if(team2Winner) {
        team2ScoreStyle = {"color": team2ScoreColor, "fontSize": "4.5em", }
    }

    return (
        <div className="card-container">
            <div className="grid-container">
                <div className="team-name" style={{color: team1ScoreColor}}>
                    <div>
                        {props.team1Players[0]}
                    </div>
                    <div style={{color: "white"}}>
                    {(props.team1Players.length > 1) ? "&" : ""}
                    </div>
                    <div>
                        {props.team1Players[1]}
                    </div>
                </div>
                <div className="grid-item">
                <div id="score">
                    <div className="score-number" style={team1ScoreStyle}>
                        {props.team1Score}
                    </div>
                    <div className="score-number">
                        -
                    </div>
                    <div className="score-number" style={team2ScoreStyle}>
                        {props.team2Score}
                    </div>
                    </div>
                </div>
                <div className="team-name" style={{color: team2ScoreColor}}>
                    <div>
                        {props.team2Players[0]}
                    </div>
                    <div style={{color: "white"}}>
                        {(props.team2Players.length > 1) ? "&" : ""}
                    </div>
                    <div>
                        {props.team2Players[1]}
                    </div>
                </div>
                <div className="goals-card-title">
                    Goals
                </div>
                <div className="grid-item">
                    
                    <div className="black-replays">
                        {   
                                props.team1GoalClipUrls.map((item, index) =>{
                                    return(
                                    <div 
                                        className="goal-replay-button"
                                        style={{borderColor: team1ScoreColor}}  
                                        id={index} key={"T1" + index} 
                                        onClick={(e) => {
                                            props.onGoalReplayClick(
                                                team1GoalClipUrls[e.target.id]
                                                    .replace('s3://sfb-prod1-games', gameClipUrl)
                                            )}
                                        }
                                    >
                                        {index+1}
                                    </div>
                                    )
                                })
                            }
                    </div>
                    <div className="red-replays">
                        {   
                            props.team2GoalClipUrls.map((item, index) =>{
                                return(
                                <div 
                                    className="goal-replay-button"
                                    style={{borderColor: team2ScoreColor}} 
                                    id={index} key={"T2" + index} 
                                    onClick={(e) => {
                                        props.onGoalReplayClick(
                                            team2GoalClipUrls[e.target.id]
                                                .replace('s3://sfb-prod1-games', gameClipUrl)
                                        )}
                                    }
                                >
                                    {index+1}
                                </div>
                                )
                            })
                        }

                    </div>
                    
                </div>
                <div className="grid-item">
                    {
                        montage_url ? <img src={require("../assets/video_replay.png")} className="nav-icon" alt="replay" onClick={(e) => {
                        
                            props.onMontageClick(montage_url.replace('s3://sfb-prod1-games', gameMontageUrl))}}/> : null
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default GameCard;
import Modal from "@cloudscape-design/components/modal"
import { useState, useEffect} from 'react';
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import winnerImg from'../assets/winner.gif';


let WinnerModal = (props) => {
    let colorMap = {
        "1": "Red",
        "2": "Blue"
    }
    //console.log(props.endGameDetails)
    const delay = ms => new Promise(res => setTimeout(res, ms));

    let autoclose = async() => {
        await delay(20000);
        console.log("Waited 20");
        props.setWinnerModalVisible(false)
      }

    useEffect(() =>{
        autoclose()

    }, [])

    return (
        <div>
            <Modal 
                onDismiss={() => props.setWinnerModalVisible(false)}
                visible={props.winnerModalVisible}
                header= {"Team " + colorMap[props.endGameDetails?.winner] + " Wins!"}
            >
                
                <br />
                <img src={winnerImg} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}/>

            </Modal>
        </div>
    )

}

export default WinnerModal;
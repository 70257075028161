
import './LandingPage.css';
import backgroundGif from '../assets/foosball.gif'

import Button from "@cloudscape-design/components/button";

import { useState, useEffect} from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';


let LangingPage = (props) => {
    //const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    //console.log('react search params')
    //console.log(searchParams.get('clientId'))
    let clientId = props.clientId

    let addFlashbarItem = props.addFlashbarItem
    return (
        <div className="hero">
            <div className="get-started-button">
                <Button
                    onClick={(event) => {
                        navigate('/register', { state: {clientId: clientId}})
                    }}
                    variant="primary"
                >
                    Register
                </Button>
                <br />
                <br />
                <Button
                    onClick={(event) => {
                        navigate('/client-view')
                    }}
                    variant="secondary"
                >
                    Previous Games
                </Button>
            </div>

            
        </div>
    )
}


export default LangingPage;
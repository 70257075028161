import { API } from 'aws-amplify';


let SERVICE_URL = 'https://riu8pn3pfl.execute-api.us-east-1.amazonaws.com/prod/'


function graphqlOperationWithAPIKey(query, variables) {
    return {query: query, variables: variables, authMode: 'API_KEY'}
 }

async function subscribe(query, callback){

    await API.graphql(
        graphqlOperationWithAPIKey(query)).subscribe({ next: callback } );

}

export { subscribe }




async function internalFetch(fetchUrl) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      //console.log(fetchUrl)
      fetch(fetchUrl, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("midway_id_token")
          },
        })
        .then(
          res => res.json(),
          error => {
            console.log("fetch", error);
            reject(error);
          }
        )
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else if (result && result.body) {
              resolve(result.body);
            }
            else {
              resolve(result);
            }
          },
          (error) => {
            console.log("fetch", error);
            reject(error);
          }
        );
    });
  }

async function clientLogin(clientDetails) {
  let fetchUrl = SERVICE_URL + "client/login/"
  //console.log("*******")
  //console.log(clientDetails)
  return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          },
          body: JSON.stringify(clientDetails)
      })
      .then(res => res.json())
      .then(
          (result) => {
          if (result && result.errorMessage) {
              reject(result.errorMessage);
          }
          else {
              resolve(result);
          }
          },
          (error) => {
          console.log("saveItem", error);
          reject(error);
          }
      );
  });
  }  


async function startGame(startObj) {
let fetchUrl = "https://riu8pn3pfl.execute-api.us-east-1.amazonaws.com/prod/game/";
console.log("*******")
console.log(startObj)
return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    /* global fetch */
    fetch(fetchUrl, {
        method: 'POST',
        headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(startObj)
    })
    .then(res => res.json())
    .then(
        (result) => {
        if (result && result.errorMessage) {
            reject(result.errorMessage);
        }
        else {
            resolve(result);
        }
        },
        (error) => {
        console.log("saveItem", error);
        reject(error);
        }
    );
});
}  

async function getActiveGame(clientId) {
  let fetchUrl = SERVICE_URL + "game/search/active/?clientId=" + clientId;
  return internalFetch(fetchUrl);
}

async function getCompletedGames(clientId) {
  let fetchUrl = SERVICE_URL + "game/search/completed/?clientId=" + clientId;
  return internalFetch(fetchUrl);
}

async function getStreamUrl(clientId) {
  let fetchUrl = SERVICE_URL + "game/stream/?clientId=" + clientId;
  return internalFetch(fetchUrl);
}

async function endGame(clientId) {
  let fetchUrl = SERVICE_URL + "game/";
  console.log("*******")
  console.log(clientId)
  return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'DELETE',
          headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          },
          body: JSON.stringify(clientId)
      })
      .then(res => res.json())
      .then(
          (result) => {
          if (result && result.errorMessage) {
              reject(result.errorMessage);
          }
          else {
              resolve(result);
          }
          },
          (error) => {
          console.log("saveItem", error);
          reject(error);
          }
      );
  });
  }  

export { startGame, getActiveGame, endGame, clientLogin, getStreamUrl, getCompletedGames }
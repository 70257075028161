/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribe = /* GraphQL */ `
  subscription Subscribe($clientId: String!) {
    subscribe(clientId: $clientId) {
      clientId
      lastUpdate
      __typename
    }
  }
`;

export const subscribeStart = /* GraphQL */ `
  subscription Subscribe($clientId: String!) {
    subscribeStart(clientId: $clientId) {
      clientId
      lastUpdate
      __typename
    }
  }
`;

export const subscribeEnd = /* GraphQL */ `
  subscription Subscribe($clientId: String!) {
    subscribeEnd(clientId: $clientId) {
      clientId
      team1Score
      team2Score
      winner
      __typename
    }
  }
`;

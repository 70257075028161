import * as React from "react";
import { useState, useEffect } from 'react';

import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Spinner from "@cloudscape-design/components/spinner";

import { useNavigate, useLocation }  from 'react-router-dom';

import { startGame, getActiveGame } from "../utils/API";



let PlayerRegistration = (props) => {
    const TEAM1COLOR = process.env.REACT_APP_TEAM_1_COLOR
    const TEAM2COLOR = process.env.REACT_APP_TEAM_2_COLOR

    const [ team1Players, setTeam1Players ] = useState({"p1": TEAM1COLOR + "Player 1", "p2": ""})
    const [ team2Players, setTeam2Players ] = useState({"p1": TEAM2COLOR + "Player 1", "p2": ""})
    const [ isTeam2SecondPlayer, setIsTeam2SecondPlayer ] = useState(false)
    const [ isTeam1SecondPlayer, setIsTeam1SecondPlayer ] = useState(false)
    const [ team1PlayerError, setTeam1PlayerError ] = useState("")
    const [ team2PlayerError, setTeam2PlayerError ] = useState("")
    const [ formErrorText, setFormErrorText ] = useState("")
    const [ loading, setLoading ] = useState(false)

    const navigate = useNavigate();

    const {state} = useLocation();
    let  clientId  = sessionStorage.getItem('clientId');

    useEffect(() =>{
        if (!clientId){
            console.log("client not logged in")
            navigate('/')
        } 
        
        checkGameStatus(clientId)
    
      }, [])

      let checkGameStatus = (clientId) => {

        getActiveGame(clientId).then(
          (result) => {
            console.log(result)
            if(result !== "No Active Games"){
                console.log('active game found')
                navigate('/client-view')
            } 
          },
          (error) => {
    
          }
        )
    
      }
    

    let processName = (input) => {
        //remove spaces
        input = input.replace(/\s+/g, '');

        //remove special chars
        input = input.replace(/[^a-zA-Z ]/g, "")

        //limit string to 10 chars
        input = input.substring(0,10)

        return input
    }

    let validateForm = () => {
        let validationResult = true;
        if(team1Players['p1']  === ""){
            setTeam1PlayerError("Please enter a name" )
            validationResult = false
        } else {
            setTeam1PlayerError("")
        }
        if(team2Players['p1']  === ""){
            setTeam2PlayerError("Please enter a name" )
            validationResult = false
        } else {
            setTeam2PlayerError("")
        }
        return validationResult
    }

    return (
        <div>
            { loading ? <Spinner /> :
            <form onSubmit={e => e.preventDefault()}>
                <Form
                    actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button 
                            formAction="none" 
                            variant="link"
                            onClick={(event) => {
                                setTeam1Players({"p1": "", "p2": ""})
                                setTeam2Players({"p1": "", "p2": ""})
                                setTeam1PlayerError("")
                                setTeam2PlayerError("")
                                setFormErrorText("")
                                setIsTeam1SecondPlayer(false)
                                setIsTeam2SecondPlayer(false)
                            }}
                        >
                        Clear
                        </Button>
                        <Button 
                            variant="primary"
                            onClick={(event) => {
                                if(validateForm()){
                                    let startObj = {
                                        "clientId": clientId,
                                        "t1Players": [],
                                        "t2Players": []
                                    }
                                    startObj['t1Players'].push(team1Players['p1'])
                                    startObj['t2Players'].push(team2Players['p1'])
                                    if(team1Players['p2']!==""){
                                        startObj['t1Players'].push(team1Players['p2'])
                                    }
                                    if(team2Players['p2']!==""){
                                        startObj['t2Players'].push(team2Players['p2'])
                                    }
                                    console.log('startObj')
                                    console.log('test')
                                    console.log(startObj['t1Players'])
                                    setLoading(true)
                                    startGame(startObj).then(
                                        (result) => {
                                            console.log('result ' + result)
                                            console.log(result['body'])
                                            props.setIsGameInProgress(true)
                                            setLoading(false)
                                            navigate("/client-view")
                                        },
                                        (error) => {
                                            console.log('error ' + error)
                                        }
                                    )
                                    //window.location.reload(false);
                                }

                            }}
                        >
                            Start Game
                        </Button>
                    </SpaceBetween>
                    }
                    errorText={formErrorText}
                >
                    <Container
                    header={
                        <Header variant="h2">
                            Register to Play
                        </Header>
                    }
                    >
                    <SpaceBetween direction="vertical" size="l">
                        <div>
                            <FormField label={TEAM1COLOR + " Player 1 Name"} errorText={team1PlayerError}>
                                <Input
                                    value={team1Players['p1']} 
                                    onChange={(event) => {
                                        setTeam1Players({...team1Players, "p1": processName(event.detail.value)})
                                    }}
                                
                                />
                            </FormField>
                            <br />
                            { !isTeam1SecondPlayer &&
                            <Button
                                onClick={(event) => {
                                    if(team1Players['p1']!== ""){
                                        setIsTeam1SecondPlayer(true)
                                    }      
                                }}
                            >
                                {"Add " + TEAM1COLOR + " Player"} 
                            </Button>
                            }
                            { isTeam1SecondPlayer &&
                            <FormField label={TEAM1COLOR + " Player 2 Name"}>
                                <Input 
                                    value={team1Players['p2']} 
                                    onChange={(event) => {
                                        setTeam1Players({...team1Players, "p2": processName(event.detail.value)})
                                    }}
                                />
                            </FormField>
                            }
                        </div>
                        <div>
                            <FormField label={TEAM2COLOR + " Player 1 Name"} errorText={team2PlayerError}>
                                <Input 
                                    value={team2Players['p1']} 
                                    onChange={(event) => {
                                        setTeam2Players({...team2Players, "p1": processName(event.detail.value)})
                                    }}
                                />
                            </FormField>
                            <br />
                            { !isTeam2SecondPlayer &&
                            <Button
                                onClick={(event) => {
                                    if(team2Players['p1']!== ""){
                                        setIsTeam2SecondPlayer(true)
                                    }      
                                }}
                            >
                                {"Add " + TEAM2COLOR + " Player"} 
                            </Button>
                            }
                            { isTeam2SecondPlayer &&
                            <FormField label={TEAM2COLOR + " Player 2 Name"}>
                                <Input 
                                    value={team2Players['p2']} 
                                    onChange={(event) => {
                                        setTeam2Players({...team2Players, "p2": processName(event.detail.value)})
                                    }}
                                />
                            </FormField>
                            }
                        </div>
                    </SpaceBetween>
                    </Container>
                </Form>
            </form>
            }
        </div>
    )
}

export default PlayerRegistration
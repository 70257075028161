import "./ClientView.css"

import { useState, useEffect} from 'react';

import { useNavigate, useLocation }  from 'react-router-dom';

import Scoreboard from '../components/Scoreboard';
import Video from '../components/Video';
import GameCard from '../components/GameCard';
import MP4Video from "../components/MP4Video";
import WinnerModal from "../components/WinnerModal";

import Button from "@cloudscape-design/components/button";

import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { subscribe, subscribeStart, subscribeEnd } from '../graphql/subscriptions';
import awsExports from '../aws-exports';

import { getActiveGame, getStreamUrl, getCompletedGames, endGame, startGame } from "../utils/API";

let ClientView = () => {
    let  clientId  = sessionStorage.getItem('clientId');
    const [ videoUrl, setVideoUrl ] = useState("")
    const [ videoType, setVideoType ] = useState("hls")
    const [isGoalReplay, setIsGoalReplay ] = useState(false)
    const navigate = useNavigate();
    const [ activeGame, setActiveGame ] = useState({})
    const [ isGameInProgress, setIsGameInProgress ] = useState(false)
    const [ streamUrl, setStreamUrl ] = useState("")
    const [ completedGames, setCompletedGames ] = useState([])
    const [ winnerModalVisible, setWinnerModalVisible ] = useState(false)
    const [ endGameDetails, setEndGameDetails ] = useState({})

    Amplify.configure(awsExports);

    useEffect(() =>{
        if (!clientId){
            console.log("client not logged in")
            navigate('/')
        } else{
        const sub = API.graphql({
            query: subscribe,
            variables: {
                clientId: clientId
            }
            
            }).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                refreshPage()
            },
            error: (error) => console.warn(error)
            }, );
        const subStart = API.graphql({
            query: subscribeStart,
            variables: {
                clientId: clientId
            }
            
            }).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                console.log('newgame')
                newGame()
                  
            },
            error: (error) => console.warn(error)
            }, );
        const subEnd = API.graphql({
            query: subscribeEnd,
            variables: {
                clientId: clientId
            }
            
            }).subscribe({
            next: ({ provider, value }) => {
                console.log({ provider, value })
                console.log('gameEnded')
                console.log(value?.data?.subscribeEnd)
                setEndGameDetails(value?.data?.subscribeEnd)
                setWinnerModalVisible(true)
                
                    
            },
            error: (error) => console.warn(error)
            }, );
        
        refreshPage()
        refreshStream()
        }
    
      }, [])

      const delay = ms => new Promise(res => setTimeout(res, ms));

      let newGame = async() => {
        await delay(3000);
        console.log("Waited 3s");
        refreshStream()
        refreshPage()
        await delay(5000);
        console.log("Waited 5s");
        refreshStream()
        refreshPage()
      }

      let refreshPage = () => {
        checkGameStatus(clientId);
        getCompletedGamesWrapper();
        //getStreamUrlWrapper();
        //setVideoType("hls")
        //setIsGoalReplay(false)
      }

      let refreshStream = () => {
        getStreamUrlWrapper();
        setVideoType("hls")
        setIsGoalReplay(false)
      }

      let getCompletedGamesWrapper = () => {
        getCompletedGames(clientId).then(
            (result) => {
                console.log(result)
                setCompletedGames(sortCompletedGames(result))
            },
            (error) => {
                console.log('error ' + error)
            }
        )
      }

      let sortCompletedGames = (completedGames) => {
        let sortedGames = completedGames.sort(function(a,b){ 
            var x = a.startTime < b.startTime? 1:-1; 
            return x; 
        });
        //console.log('sorted')
        //console.log(sortedGames)
        return sortedGames;

      }

      let getStreamUrlWrapper = () => {
        getStreamUrl(clientId).then(
            (result) => {
                console.log('result ' + result)
                setStreamUrl(result)
            },
            (error) => {
                console.log('error ' + error)
            }
        )
      }


      let checkGameStatus = (clientId) => {

        getActiveGame(clientId).then(
          (result) => {
            //console.log(result)
            if(result !== "No Active Games"){
              setIsGameInProgress(true)
              setActiveGame(result)
              //setGameId(result?.['id'])
            } else {
              setIsGameInProgress(false)
             //setGameId(0)
            }
          },
          (error) => {
    
          }
        )
    
      }
    

      let onGoalReplayClick =(url) => {
        // console.log('playing replay')
        console.log(url)
         setStreamUrl(url)
         setVideoType("video/mp4")
         setIsGoalReplay(true)
     }

     let onReturnToLive = () => {
        //console.log("returning to live")
        setVideoType("hls")
        //setVideoUrl(streamUrl)
        setIsGoalReplay(false)
        refreshPage()
        refreshStream()
    }

      

    let sortAndReturnGoalClipsUrlArray = (goalClipsObject) => {
        let arr;
        if(goalClipsObject){
            let ordered = Object.keys(goalClipsObject).sort().reduce(
                (obj, key) => {
                    obj[key] = goalClipsObject[key]
                    return obj;
                },
                {}
            )
            //console.log(ordered)
            arr = Object.keys(ordered).map((k) => ordered[k])
            
        } else {
            arr = []
        }
        return arr
        
    }

    sortAndReturnGoalClipsUrlArray(activeGame['Team1GoalClipUrls'])  
    

    

    return(
        <div>
            <WinnerModal setWinnerModalVisible={setWinnerModalVisible} winnerModalVisible={winnerModalVisible} endGameDetails={endGameDetails} setEndGameDetails={setEndGameDetails}/>
            <div style={{marginLeft: "0"}}>
            <Button variant="primary"
                disabled={!isGameInProgress}
                onClick={(event) => {
                    console.log('ending game')
                    endGame({"clientId": clientId}).then(
                        (result) => {
                          //console.log(result)
                          if(result !== "No Active Games"){
                            //setIsGameInProgress(true)
                            //setActiveGame(result)
                            refreshPage()
                            //setGameId(result?.['id'])
                          } else {
                            setIsGameInProgress(false)
                           //setGameId(0)
                          }
                        },
                        (error) => {
                  
                        }
                      )
                }}

            >End Game</Button>
            <Button variant="primary"
                disabled={isGameInProgress}
                onClick={(event) => {
                    navigate('/register', { state: {clientId: clientId}})  
                }}
            >Register</Button>
            <Button variant="normal"
                onClick={(event) => {
                    
                      refreshPage()
                      refreshStream()
                    
                }}
            >Refresh to Live</Button>
            <br />
            <br />
            </div>
            <div className="content-container">
                <div className="score-and-video">
                    <Scoreboard 
                        gameStatus={isGameInProgress}
                        team1Score={activeGame['Team1Score']} 
                        team2Score={activeGame['Team2Score']} 
                        team1Players={activeGame['Team1Players']} 
                        team2Players={activeGame['Team2Players']} 
                        team2GameClipUrls={sortAndReturnGoalClipsUrlArray(activeGame['Team2GoalClipUrls'])} 
                        team1GameClipUrls={sortAndReturnGoalClipsUrlArray(activeGame['Team1GoalClipUrls'])}
                        onGoalReplayClick={onGoalReplayClick}
                    />

                    <div>
                        <div style={{maxWidth: "100%"}}>
                        {
                            videoType === "hls" ?<Video videoUrl={streamUrl} type={"application/x-mpegURL"} style={{width: "30%"}}/>: <MP4Video videoUrl={streamUrl} type={"video/mp4"} />
                            
                        }
                        {
                            isGoalReplay?<button onClick={() => {onReturnToLive()}}>Return to Live</button>: null
                        }
                            
                        </div>
                    </div>
                </div>

        
            <div className="games-and-logo">
                <div className="logo">
                    
                </div>
                <br />
                <div className="games">
                        {
                            completedGames.map((game, index) => {
                                return(
                                        <GameCard 
                                        key={game.startTime + index}
                                        id={game.startTime + index}
                                        montageUrl={game.MontageUrl}
                                        onMontageClick={onGoalReplayClick}
                                        team1Score={game.Team1Score}
                                        team2Score={game.Team2Score}
                                        team1Players={game.Team1Players}
                                        team2Players={game.Team2Players}
                                        team1GoalClipUrls={sortAndReturnGoalClipsUrlArray(game.Team1GoalClipUrls)}
                                        team2GoalClipUrls={sortAndReturnGoalClipsUrlArray(game.Team2GoalClipUrls)}
                                        onGoalReplayClick={onGoalReplayClick}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    )

}

export default ClientView;
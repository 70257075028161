
import Button from "@cloudscape-design/components/button";

import { endGame } from "../utils/API";


let GameView = (props) => {

    let handleEndGame = () => {
       let payload =  {
            "arguments": {
              "input": {
                "id": props.gameId
              }
            }
          }
        console.log(payload)
        endGame(payload).then(
            (result) => {
                console.log(result)
                window.location.reload(false);
            },
            (error) => {
                console.log(error)
                window.location.reload(false);
            }
        )   
    }

    return (
        <div>
            Game in Progress
            <br />
            <br />
            <br />
            <img src={require('../assets/sfb-mobile-qr.png')} width={250}/>
            <br />
            <br />
            <br />
            <Button
                onClick={(event) => {
                    handleEndGame()
                    
                }}
            >
                End Game
            </Button>
        </div>
    )
}

export default GameView;


let MP4Video = ({ videoUrl }) => {
    console.log("Playing: " + videoUrl)

        return (
            <div >
                <video autoPlay loop controls={true} webkit-playsinline={"true"} playsInline id="live-stream" key={videoUrl} width={"100%"}>
                    <source src={videoUrl} type="video/mp4" />
                </video>         
            </div>
        )

}


export default MP4Video;
